import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import ComingSoon from "../../../sections/coming-soon"

const Entertainment = () => (
  <Layout>
    <Seo
      title="Entertainment"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <ComingSoon />
  </Layout>
)

export default Entertainment
